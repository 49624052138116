import { get } from 'lodash';
import moment from 'moment';
import { getFirmChangedFirmReviewMessage, REVIEW_TYPE } from '../../__constants__';
import { generateRatingLabels, getReviewTypeTitle } from '../../data/attributeHelper';
import StarRatingDisplay from "../StarRatingDisplay";
import { palette } from '../../Theme';

function ProfileClientReview (props) {
    const renderOverallRating = review => {
        return (
            <div className='flex items-center mb-4 max-sm:m-0 max-sm:mt-1 max-xs:mt-2 max-sm:[&_svg]:!text-[1.2rem] max-xs:justify-center'>
                <div className='mr-1'>
                    <StarRatingDisplay nativeColor={palette.secondary.main} rating={Math.round(review.average_score)} readOnly />
                </div>

                <p className='text-base font-bold max-sm:leading-[0.4] max-sm:text-[.875rem]'>Overall</p>
            </div>
        )
    }

    const renderRatings = review => {
        const isReviewType = review.reviewer_type === 'review';

        return (
            <div>
                {isReviewType && review.review_scores &&
                    Object.entries(review.review_scores).map(([key, value]) =>
                        <div className='flex text-left items-center mb-2 max-sm:[&_svg]:!text-[1.2rem]' key={key}>
                            <div className='mr-1'>
                                <StarRatingDisplay rating={Math.round(value)}/>
                            </div>

                            <p className='text-[.875rem] leading-[1.43] max-sm:leading-[0.4] font-normal m-0'>
                                { generateRatingLabels(key) }
                            </p>
                        </div>
                    )
                }
            </div>
        );
    };

    const renderReview = (question, answer, professionalFirstName, repeatReview, gka) => {
        return (
            <div key={question}>
                {repeatReview && gka == 'how_did_help_you_since_last_review' &&
                    <p className='font-bold text-mint-green mb-4 text-[0.875rem] leading-[1.43] m-0 border border-solid border-mint-green p-2'>
                        This review is a follow-up review from a client who left a review previously.
                    </p>
                }

                <p className='font-bold text-[0.875rem] leading-[1.43] max-xs:text-sm m-0'>
                    { question.replace('{{first_name}}', professionalFirstName) }
                </p>

                <p className='mb-4 text-[1.1rem] leading-[1.5] max-xs:text-sm m-0 whitespace-pre-wrap break-words'>
                    { answer }
                </p>
            </div>
        );
    }

    const renderReviewContent = (review, professionalFirstName) => {
        const questions = get(review, 'questions', null);

        const professionalResponse = get(review, 'professional_response', null);
        const repeatReview = get(review, 'repeat_review', null);

        if (!questions) return null;

        const questionsList = questions.map(question => {
            return renderReview(question.label_template, question.answer, professionalFirstName, repeatReview, question.question.gka)
        });

        return (
            <>
                { questionsList }

                {professionalResponse &&
                    <div class="bg-very-pale-mint-green p-4 rounded-lg flex-1 max-xs:mb-3 max-xs:text-sm">
                        <p className='font-bold m-0'>{professionalFirstName}:</p>

                        { professionalResponse }
                    </div>
                }
            </>
        );
    };

    const reviewMessage = (review, isFirmReview) => {
        if (isFirmReview && review.firm && review.firm.id !== review.professional.firm_id) {
            return getFirmChangedFirmReviewMessage(review.firm.type);
        }

        return '';
    }

    const renderIsFromPreviousFirmMessage = (review, classes, isFirmReview) => {
        if (review.is_from_previous_firm && !isFirmReview) {
            return "This review was gathered whilst the adviser was at a different firm.";
        }

        return null;
    }

    const { classes, review, type, professionalFirstName, professionalLastName, isFirmReview } = props;
    const isFirstImpression = type === REVIEW_TYPE.FIRST_IMPRESSION;

    const reviewTitle = getReviewTypeTitle(review.repeat_review, isFirstImpression, isFirmReview, professionalFirstName, professionalLastName)

    const adviserLeftFirmMessage = reviewMessage(review, isFirmReview)
    const reviewForPreviousAdviserFirmMessage = renderIsFromPreviousFirmMessage(review, classes, isFirmReview)

    return (
        <div className='px-16 max-sm:px-10 max-xs:px-4 pb-6 max-sm:pb-3 max-xs:pb-0 pt-8 max-sm:pt-7 max-xs:pt-3.5 rounded-lg shadow-md bg-white' id={`review${review.id}`}>
            <div className='m-0 w-[calc(100% + 32px)] flex max-sm:flex-col box-border'>
                <div className='sm:basis-3/12 xs:min-w-[278px] pr-6 max-sm:flex max-sm:justify-between max-xs:flex-col'>
                    <div>
                        <h6 className='text-xl m-0 max-sm:text-lg max-xs:text-md max-xs:text-center'>
                            {reviewTitle.reviewType} {reviewTitle.subject !== null ? `of ${reviewTitle.subject}` : ''}
                        </h6>

                        <p className='text-sm m-0 py-1 max-xs:text-xs max-xs:text-center'>
                            by a {reviewTitle.author}{isFirstImpression ? '' : ` in ${review.location}`}
                        </p>

                        <p className='text-sm m-0 italic pb-4 max-sm:pb-0 max-xs:text-xs max-xs:text-center'>
                            {moment(review.submitted_at).fromNow()}
                        </p>

                        {adviserLeftFirmMessage || reviewForPreviousAdviserFirmMessage &&
                            <div className='py-2 max-xs:text-center'>
                                {adviserLeftFirmMessage &&
                                    <p className='text-sm max-xs:text-xs text-cherry-red m-0 py-2'>
                                        {adviserLeftFirmMessage}
                                    </p>
                                }
                                
                                {reviewForPreviousAdviserFirmMessage &&
                                    <p className='text-sm max-xs:text-xs text-cherry-red m-0 py-2'>
                                        {reviewForPreviousAdviserFirmMessage}
                                    </p>
                                }
                            </div>
                        }

                        {renderOverallRating(review)}
                    </div>
                    
                    <div className='max-xs:flex max-xs:justify-center'>
                        {renderRatings(review)}
                    </div>
                    
                </div>

                <div className='sm:basis-9/12 mt-0.5 min-w-[100px] max-sm:mt-3'>
                    {
                        renderReviewContent(review, professionalFirstName)
                    }
                </div>
            </div>
        </div>
    );
}

export default ProfileClientReview;
