const ProfileReviewRatingTiers = ({
  reviewCount,
  ratings = [],
  filterTiers = true,
  tierFilters,
  handleTierFilterChange,
}) => {
  return (
    <div className='w-full'>
      <div className='w-full flex mt-6 flex-col ratings-wrapper'>
        {ratings.map((rating) => {
          const maxPercentage = 100;
          const { count: ratingCount } = rating;
          const calculatedPercentage = reviewCount === 0 ? 0 : Math.ceil((ratingCount / reviewCount) * maxPercentage);
          const percentage = Math.min(calculatedPercentage, maxPercentage);

          const handleClick = () => {
            if (percentage === 0) {
              return;
            }

            handleTierFilterChange(rating.tier)
          }
          
          return (
            <>
              {filterTiers && (
                <div
                  key={rating.tier}
                  onClick={handleClick}
                  className={`
                    flex items-center mb-1.5 
                    ${percentage === 0 ? 'cursor-not-allowed' : 'cursor-pointer [&_p]:hover:text-secondary-blue [&_.bar-wrapper]:hover:bg-contrast-blue [&_.bar]:hover:bg-secondary-blue'} 
                    ${tierFilters.includes(rating.tier) ? '[&_.bar]:bg-secondary-blue [&_.tier-label_p]:text-secondary-blue [&_p]:font-bold [&_.count-label_p]:text-secondary-blue [&_.bar-wrapper]:bg-contrast-blue' : ""}
                  `}
                >
                  <div className='tier-label'>
                    <p className={`text-[1.2rem] max-sm:text-sm font-normal mr-3 m-0 ${percentage === 0 ? 'text-light-grey' : 'text-primary-blue'}`}>
                      {rating.tier}
                    </p>
                  </div>

                  <div className={`flex w-full ${percentage === 0 ? 'bg-gray-50' : 'bg-contrast-grey'} rounded-full [&_div]:h-3 max-sm:[&_div]:h-2.5 bar-wrapper`}>
                    <div
                      style={{ width: `${percentage}%` }}
                      className='bg-primary-blue rounded-full bar'
                    />
                  </div>

                  <div className='min-w-36 max-sm:min-w-[5.5rem] text-left ml-2 count-label'>
                    <p className={`text-md max-sm:text-sm m-0 ${percentage === 0 ? 'text-light-grey' : 'text-primary-blue'}`}>
                      {ratingCount.toLocaleString("en-UK")} {ratingCount === 1 ? 'review' : 'reviews'}
                    </p>
                  </div>
                </div>
              )}

              {!filterTiers && (
                <div key={rating.tier} className='flex items-center mb-3'>
                  <div className='tier-label'>
                    <p className='text-[1.2rem] font-normal mr-3 m-0'>
                      {rating.tier}
                    </p>
                  </div>
                  <div className={`flex w-full bg-contrast-grey rounded-full [&_div]:h-3  bar-wrapper`}>
                    <div
                      style={{ width: `${percentage}%` }}
                      className='bg-primary-blue rounded-full bar'
                    />
                  </div>
                  <div className='min-w-36 text-left ml-2 count-label'>
                    <p className='text-md m-0 text-primary-blue'>
                      {rating.count.toLocaleString("en-UK")} {ratingCount === 1 ? 'review' : 'reviews'}
                    </p>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileReviewRatingTiers;
