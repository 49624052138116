import Link from 'next/link'
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { getCurrentPaginationPageFromLocation } from '../../utils/firmPageUtils';
import { useRouter } from 'next/router';

const ReviewPagination = ({ baseUrl, pagesCount, onChange = () => {} }) => {
    const router = useRouter()
    const currentPage = getCurrentPaginationPageFromLocation(router);

    return (
        <Pagination
            page={currentPage}
            className='[&_.MuiPaginationItem-root]:text-primary-blue [&_.MuiPaginationItem-root]:text-lg max-xs:[&_.MuiPaginationItem-root]:text-sm [&_.MuiPaginationItem-root]:font-bold [&_.MuiPagination-ul]:justify-center'
            count={pagesCount}
            onChange={onChange}
            renderItem={(item) => (
                <Link href={item.page === 1 ? baseUrl : `${baseUrl}?page=${item.page}`} shallow passHref>
                    <PaginationItem
                        {...item}
                    />
                </Link>
            )}
        />
    );
};

export default ReviewPagination;
