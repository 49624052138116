import {forwardRef} from "react";
import CloseNoBackground from "./vectors/CloseNoBackground";

const MultiselectChip = forwardRef(({ key, label, onDelete, disabled = false }, ref) => {
    const onDeleteDecorator = (e) => {
        if (disabled) {
            return;
        }

        onDelete(e)
    }

    return (
        <span key={key} ref={ref}
             className={`cursor-pointer border-0 font-bold h-8 inline-flex outline-0 p-0 text-sm box-border transition-colors items-center whitespace-nowrap align-middle justify-center decoration-0 m-0.5 rounded-full bg ${ disabled ? 'text-gray-500' : 'text-secondary-blue'}`}
             tabIndex="0">
            <span className="overflow-hidden whitespace-nowrap px-0 overflow-ellipsis">{label}</span>
            
            <CloseNoBackground onDelete={onDeleteDecorator} />
        </span>
    )
})

export default MultiselectChip