import { useState, useEffect } from "react";
import { get, isEmpty } from "lodash";
import ProfileClientReview from "../../../Profile/ProfileClientReview";
import ProfileClientReviewsLabel from "../../../Profile/ProfileClientReviewsLabel";
import LoadingSpinner from "../../../LoadingSpinner";
import ProfileClientReviewsEmpty from "../../../Profile/ProfileClientReviewsEmpty";
import ReviewFilters, { stringifyQuery } from "./ReviewFilters";
import ProfileReviewRatingTiers from "./ProfileReviewRatingTiers";
import {
  calculatePaginationOffset,
  calculatePaginationPagesCount,
  getCurrentPaginationPageFromLocation,
} from "../../../../utils/firmPageUtils";
import { useRouter } from "next/router";
import {
  API_KEYS,
  PERSISTED_FIRM_REVIEW_FILTERS,
} from "../../../../__constants__";
import ReviewPagination from "../../../ReviewPagination/ReviewPagination";
import { track } from "../../../../analytics";
import useAbortableRequest from "../../../../hooks/useAbortableRequest";

export const FIRM_PAGINATION_REVIEWS_PER_PAGE = 20;

const INITIAL_STATE = {
  reviews: [],
  loading: true,
  error: false,
  totalReviewCount: 0,
};

const emptyTiers = [{
  count: 0,
  tier: 5
},
{
  count: 0,
  tier: 4
},{
  count: 0,
  tier: 3
},{
  count: 0,
  tier: 2
},{
  count: 0,
  tier: 1
},];

const fetchReviewData = async (url, requestSignal) => {
  const res = await fetch(url, { signal: requestSignal });
  const json = await res.json();

  return {
    reviews: get(json, "data", []),
    totalReviewCount: get(json, "aggregate_data.total", 0),
  };
};

const generateReviewsApiEndpointUrl = (
  id,
  reviewFiltersQuery,
  firmOrNetworkType,
  router
) => {
  const offset = calculatePaginationOffset(router.query);

  return `${API_KEYS.API_URL}/v2/public/${firmOrNetworkType}/${id}/reviews?limit=${FIRM_PAGINATION_REVIEWS_PER_PAGE}&offset=${offset}&${reviewFiltersQuery}`;
};

const useFetchReviews = (id, router, initialReviews, firmOrNetworkType, initialPage) => {
  const [state, setState] = useState({ ...INITIAL_STATE });

  const [reviewFiltersQueryString, setReviewFiltersQueryString] = useState(
    () => {
      if (typeof window !== "undefined") {
        const reviewFilters = sessionStorage.getItem(
          PERSISTED_FIRM_REVIEW_FILTERS
        );
        return reviewFilters ? JSON.parse(reviewFilters).query : "";
      } else {
        return "";
      }
    }
  );

  const url = generateReviewsApiEndpointUrl(
    id,
    reviewFiltersQueryString,
    firmOrNetworkType,
    router
  );

  const { signal } = useAbortableRequest('new filter applied', [id, reviewFiltersQueryString, url])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pageFromRouter = router.query?.page ? router.query?.page : 1;
        if (reviewFiltersQueryString || !initialReviews || (pageFromRouter !== initialPage)) {
          setState({ ...state, loading: true });
          const { reviews, totalReviewCount } = await fetchReviewData(url, signal);
          setState({
            ...INITIAL_STATE,
            reviews: reviews,
            totalReviewCount: totalReviewCount,
            loading: false,
          });
        } else {
          setState({
            ...INITIAL_STATE,
            reviews: initialReviews.data,
            totalReviewCount: initialReviews.aggregate_data.total,
            loading: false,
          });
        }
      } catch (e) {
        if ((e.message && e.message.includes('aborted a request')) || e === 'new filter applied') {
          return;
        }

        setState({
          ...INITIAL_STATE,
          loading: false,
          error: true,
        });
      }
    };

    fetchData()
  }, [signal]);

  return {
    reviews: state.reviews,
    loading: state.loading,
    totalReviewCount: state.totalReviewCount,
    error: state.error,
    reviewFiltersQueryString: reviewFiltersQueryString,
    setReviewFiltersQueryString: setReviewFiltersQueryString,
  };
};

const ReviewsList = (props) => {
  const {
    initialReviews,
    profile,
    profileName,
    reviewFilters,
    onPaginationChange,
    firmOrNetworkType,
    initialPage,
    unclaimed
  } = props;

  const profileReviewCount = get(profile, ["review_count"], null);
  const hasReviewsFromOtherFirms = get(
    profile,
    "professional_key_attributes.reviews_from_other_firm",
    false
  );
  const reviewRatingTiers = unclaimed ? emptyTiers : get(
    profile,
    ["statistics", "review_rating_tiers"],
    emptyTiers
  );
  const reviewCount = get(profile, ["statistics", "review_count"], 0);
  const router = useRouter();

  const {
    reviews,
    totalReviewCount,
    loading,
    error,
    reviewFiltersQueryString,
    setReviewFiltersQueryString,
  } = useFetchReviews(profile.id, router, initialReviews, firmOrNetworkType, initialPage);

  const currentPage = getCurrentPaginationPageFromLocation(router);
  const pagesCount = calculatePaginationPagesCount(totalReviewCount);
  const [tierFilters, setTierFilters] = useState([]);

  useEffect(() => {
    if (firmOrNetworkType === "firm") {
      const filters = sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS)
        ? JSON.parse(sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS))
        : null;
      if (filters && filters.state?.tierFilter) {
        setTierFilters(filters.state?.tierFilter);
      }
    }
  }, []);

  if (error) {
    router.push("/404");
  }

  if (!loading && totalReviewCount > 0 && currentPage > pagesCount) {
    router.push("/404");
  }

  if (!loading && totalReviewCount === 0 && currentPage !== 1) {
    router.push("/404");
  }

  const handleTierFilterChange = (tier) => {
    let newTierFilters = tierFilters;
    const index = newTierFilters.indexOf(tier);
    if (index !== -1) {
      newTierFilters.splice(index, 1);
      track(`Tier: ${tier} [deselect]`, {
        category: firmOrNetworkType === "firm" ? "Firm Profile" : "Network Profile",
      });
    } else {
      newTierFilters = [...newTierFilters, tier];
      track(`Tier: ${tier} [selected]`, {
        category: firmOrNetworkType === "firm" ? "Firm Profile" : "Network Profile",
      });
    }
    setTierFilters([...newTierFilters]);
    const query = {};
    if (newTierFilters.length) {
      query["tiers"] = newTierFilters;
    }

    handleReviewFiltersUpdate(query, newTierFilters, "tierFilter");
  };

  const renderReview = (review, i) => {
    return (
      <ProfileClientReview
        key={i}
        review={review}
        type={review.reviewer_type}
        professionalFirstName={review.professional.first_name}
        professionalLastName={review.professional.last_name}
        isFirmReview={true}
      />
    );
  };

  const handleReviewFiltersUpdate = (filtersQuery, componentState, type) => {
    const filtersFromSessionStorage = sessionStorage.getItem(
      PERSISTED_FIRM_REVIEW_FILTERS
    )
      ? JSON.parse(sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS))
      : {
          query: {},
          state: {},
          queryParts: {},
        };

    const finalQueryArray = filtersFromSessionStorage.queryParts;
    finalQueryArray[type] = filtersQuery;

    const stateToSaveToStorage = {
      ...filtersFromSessionStorage.state,
      [type]: componentState,
    };
    const queryPartsToSaveToStorage = {
      ...filtersFromSessionStorage.queryParts,
      [type]: filtersQuery,
    };

    let finalQuery = {};
    Object.keys(finalQueryArray).map((key) => {
      finalQuery = { ...finalQuery, ...finalQueryArray[key] };
      return true;
    });

    const queryString = stringifyQuery(finalQuery);

    if (
      queryString &&
      queryString !== reviewFiltersQueryString &&
      router.query?.page &&
      router.query?.page > 1
    ) {
      const { page, ...routerQuery } = router.query;
      router.push(
        {
          query: { ...routerQuery },
        },
        undefined,
        { shallow: true }
      );
      onPaginationChange();
    }

    if (queryString !== reviewFiltersQueryString) {
      setReviewFiltersQueryString(queryString);
      sessionStorage.setItem(
        PERSISTED_FIRM_REVIEW_FILTERS,
        JSON.stringify({
          query: stringifyQuery(finalQuery),
          state: stateToSaveToStorage,
          queryParts: queryPartsToSaveToStorage,
        })
      );
    }
  };

  const renderReviewList = () => {
    const isReviewListEmpty = isEmpty(reviews)
    return (
      <>
        {isReviewListEmpty &&
          <div className="px-10 pb-6 pt-8 rounded-lg shadow-md text-center">
            <p className="font-bold text-lg">No reviews matching your filter selections.</p>
          </div>
        }

        {!isReviewListEmpty && reviews.map((review, i) => renderReview(review, i))}

        {pagesCount > 1 && 
          <div className='text-center [&_*]:max-xs:text-sm'>
            <ReviewPagination
              baseUrl={`/${firmOrNetworkType}/${router.query.firmPermalink}`}
              pagesCount={pagesCount}
              onChange={onPaginationChange}
            />
          </div>
        }
      </>
    );
  };

  const shouldShowFilters = reviewCount > 0 && !unclaimed

  return (
    <section id="gta-reviews-list">
      {hasReviewsFromOtherFirms && !unclaimed && <ProfileClientReviewsLabel />}

      {props.children}

        <div className="bg-white flex flex-col justify-start pb-2 px-14 max-xs:px-4">
          <h5 className="text-2xl font-bold m-0 text-center">
            Reviews of {profileName}
          </h5>

          <div className="flex flex-row max-sm:flex-col">
            <div className={shouldShowFilters ? 'basis-3/5' : 'basis-full'}>
              <ProfileReviewRatingTiers
                ratings={reviewRatingTiers}
                reviewCount={reviewCount}
                tierFilters={tierFilters}
                handleTierFilterChange={handleTierFilterChange}
                filterTiers={true}
              />
            </div>

            {shouldShowFilters &&
              <div className='basis-2/5 border-none shadow-none'>
                <ReviewFilters
                  handleReviewFiltersUpdate={handleReviewFiltersUpdate}
                  id={profile.id}
                  reviewFilters={reviewFilters}
                  type={profile.type}
                  tierFilters={tierFilters}
                  firmOrNetworkType={firmOrNetworkType}
                  totalReviewCount={totalReviewCount}
                  loading={loading}
                />
              </div>
            }
          </div>
        </div>

      {loading ? (
        <LoadingSpinner className='mb-8 mt-8' color="inherit" />
      ) : (0 < profileReviewCount || totalReviewCount) && !unclaimed ? (
        <div className="p-8 flex flex-col gap-8 max-xs:p-2">
          {renderReviewList()}
        </div>
        
      ) : (
        <ProfileClientReviewsEmpty name={profileName} />
      )}
    </section>
  );
};

export default ReviewsList;
