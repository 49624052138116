import { useEffect, useState } from "react";

/**
 * @param {string} abortReason The reason for the request abort
 * @param {array} dependencies State dependencies that trigger a request abortion
 * 
 * @typedef {Object} AbortableRequest
 * @property {AbortSignal} signal - The signal to attach to fetch function calls
 * @returns {AbortableRequest}
 */
export default function useAbortableRequest(abortReason = 'new request started', dependencies = []) {
    const [controller, setController] = useState(new AbortController())

    useEffect(() => {
        controller.abort(abortReason)
        setController(new AbortController())
    }, dependencies)

    return {
        signal: controller.signal
    }
}