import { adviserProfileFirmReviewMessage } from '../../__constants__';
import InfoIcon from '../vectors/InfoIcon';

const ProfileClientReviewsLabel = () => {
    return (
        <div className='flex justify-center cursor-pointer [&_svg]:w-5 [&_svg]:h-5 [&_svg]:mt-0.5'>
            <InfoIcon />

            <p className='text-base text-secondary-blue font-bold m-0 ml-1'>
                {adviserProfileFirmReviewMessage()}
            </p>
        </div>
    );
};

export default ProfileClientReviewsLabel;
