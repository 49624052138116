import { useState, useEffect, useRef } from "react";
import qs from "query-string";
import {
  REVIEW_TYPE,
  PERSISTED_FIRM_REVIEW_FILTERS,
  FIRM_TYPES,
} from "../../../../__constants__";
import { getServiceLabelBy } from "../../../../data/attributeHelper";
import { track } from "../../../../analytics";
import Select from "../../../Inputs/Select";
import SelectOption from "../../../Inputs/SelectOption";
import MultiSelect from "../../../Inputs/MultiSelect";
import MultiselectChip from "../../../MultiselectChip";
import useCalculateEllipsis from "../../../../hooks/useCalculateEllipsis";

export const stringifyQuery = (query) =>
  qs.stringify(query, { arrayFormat: "bracket" });

export const parseQuery = (queryString) =>
  qs.parse(queryString, { arrayFormat: "bracket" });

const defaultProps = {
  showChipsOnly: false,
};

const AGES = [
  {
    label: "Any age group",
    value: 0
  },
  {
    label: "18-34",
    value: 1,
    min: 18,
    max: 34,
  },
  {
    label: "35-44",
    value: 2,
    min: 35,
    max: 44,
  },
  {
    label: "45-54",
    value: 3,
    min: 45,
    max: 54,
  },
  {
    label: "55-64",
    value: 4,
    min: 55,
    max: 64,
  },
  {
    label: "65+",
    value: 5,
    min: 65,
    max: 120,
  },
];

const REVIEWER_TYPE = [
  {
    value: "all",
    label: "Reviews from all users",
  },
  {
    value: REVIEW_TYPE.REVIEW,
    label: "Reviews from verified clients",
  },
  {
    value: REVIEW_TYPE.FIRST_IMPRESSION,
    label: "First impressions from non-clients",
  },
];

const INITIAL_FILTERS = {
  keyword: "",
  services: [],
  age: 0,
  reviewer_type: "all",
  changed: false,
};

const ReviewFilters = (props) => {
  const [state, setState] = useState({
    ...INITIAL_FILTERS,
  });

  const { age, reviewer_type, services } = state;
  const {
    reviewFilters = [],
    showChipsOnly,
    firmOrNetworkType,
    type,
    totalReviewCount,
    loading
  } = props;

  useEffect(() => {
    const filters = sessionStorage.getItem(PERSISTED_FIRM_REVIEW_FILTERS);

    if (filters) {
      setState({ ...state, ...JSON.parse(filters).state?.filter });
    }
  }, []);

  useEffect(() => {
    if (state.changed) {
      handleFilterChange();
    }
  }, [state.age, state.services, state.reviewer_type]);

  const handleReviewerTypeChange = (option) => {
    submitAnalytics({ label: "Reviewer type: " + option.value  + " [selected]"});

    if (option.value === REVIEW_TYPE.FIRST_IMPRESSION) {
      setState({ ...INITIAL_FILTERS, reviewer_type: option.value, changed: true });
      return
    }

    setState({
      ...state,
      reviewer_type: option.value,
      changed: true
    })
  }

  const handleAgeGroupChange = (option) => {
    handleAgeAnalytics(option.value, true);

    setState({
      ...state,
      age: option.value,
      changed: true
    })
  }

  const handleAgeAnalytics = (value, selected) => {
    const label = `Age: ${AGES[value].label} [${
      selected ? "select" : "deselect"
    }]`;

    submitAnalytics({ label });
  }

  const submitAnalytics = (options) => {
    const category =
      firmOrNetworkType == "firm" ? "Firm reviews" : "Network reviews";

    track("Filter", {
      category,
      ...options,
    });
  }

  const handleFilterChange = () => {
    const { services, age, reviewer_type } = state;
    const query = {};

    if (services.length) {
      query["services"] = services;
    }

    if (age) {
      query["age[min]"] = AGES[age].min;
      query["age[max]"] = AGES[age].max;
    }

    if (reviewer_type !== "all") {
      query.type = reviewer_type;
    }

    props.handleReviewFiltersUpdate(query, state, "filter");
  }

  const multiSelectChipRefs = useRef(new Map())
  const multiSelectRef = useRef(null)
  const shouldShowEllipsis = useCalculateEllipsis(multiSelectRef, multiSelectChipRefs, services)

  const handleSelectService = (value) => {
    const selected = value.length > state.services.length;
    const changedValue = selected
      ? value.find((id) => !state.services.includes(id))
      : state.services.find((id) => !value.includes(id));
    const label = getServiceLabelBy("id", 5, changedValue);

    if (!selected) {
      multiSelectChipRefs.current?.delete(changedValue)
    }

    submitAnalytics({
      label: `services[]: ${label} [${selected ? "select" : "deselect"}]`,
    });

    setState({
      ...state,
      changed: true,
      services: selected
        ? [...state.services, changedValue]
        : state.services.filter((v) => v !== changedValue),
    });
  }

  const handleDeleteService = (value) => {
    const label = getServiceLabelBy("id", 5, value);
    multiSelectChipRefs.current?.delete(value)

    submitAnalytics({
      label: `services[]: ${label} [deselect]`,
    });

    setState({
      ...state,
      changed: true,
      services: state.services.filter((v) => v !== value),
    });
  }

  const servicesOptions = reviewFilters.map((id) => {
    return {
      label: getServiceLabelBy("id", 5, id),
      value: id
    }
  })

  const isFirstImpressionSelected = state.reviewer_type === REVIEW_TYPE.FIRST_IMPRESSION

  return (
    <div>
      {!showChipsOnly && (firmOrNetworkType == "network" || !(firmOrNetworkType == "firm" && type == FIRM_TYPES.LEGAL_ADVISER)) &&
        <div className="flex flex-col gap-4 max-xs:gap-3 pt-7 max-xs:[&_*]:text-sm">
          <p className="text-center m-0">
            {loading && 'Fetching reviews...'}
            {!loading && <>Viewing <strong>{totalReviewCount}</strong> {totalReviewCount === 1 ? 'review' : 'reviews'}</>}
          </p>

          <div>
            <MultiSelect
              ref={multiSelectRef}
              showEllipsis={shouldShowEllipsis}
              defaultSelection={services}
              className={'max-xs:[&_button]:h-9 [&_button]:h-10 [&_button]:py-2'}
              onSelection={handleSelectService}
              disabled={isFirstImpressionSelected}
              id='services'
              options={servicesOptions}
              renderOption={(option, isSelected) => (<SelectOption label={option.label} isSelected={isSelected} disabled={isFirstImpressionSelected} />)}
              optionValue='value'
              renderValue={() => (
                <div className={'flex flex-wrap h-full items-center [&_span]:h-full [&_svg]:-mt-1'}>
                  {services.length < 1 && <p className={`text-base pl-1 m-0 ${isFirstImpressionSelected ? 'text-gray-400' : 'text-primary-blue'}`}>All services</p>}

                  {services.length > 0 && services.map(serviceId => {
                    const label = getServiceLabelBy("id", 5, serviceId)
                    
                    if (!label) {
                      return null;
                    }

                    return (
                        <MultiselectChip
                            ref={chip => {
                              if (!multiSelectChipRefs.current?.get(serviceId)) {
                                multiSelectChipRefs.current.set(serviceId, chip)
                              }
                            }}
                            key={`filter-service-multi:${serviceId}`}
                            label={label}
                            disabled={isFirstImpressionSelected}
                            onDelete={() => handleDeleteService(serviceId)}
                        />
                    )
                  })}
                </div>
              )}
            />
          </div>

          <div className="flex gap-3 max-xs:flex-col">
            <div className="basis-5/6">
              <Select
                id='review-type-select'
                defaultSelection={REVIEWER_TYPE.find(opt => opt.value === reviewer_type)}
                onSelection={handleReviewerTypeChange}
                options={REVIEWER_TYPE}
                renderOption={(option, isSelected) => (
                  <SelectOption label={option.label} isSelected={isSelected} />
                )}
              />
            </div>

            <div className="basis-1/6 min-w-[177px]">
              <Select
                id='age-group-select'
                defaultSelection={AGES.find(opt => opt.value === age)}
                onSelection={handleAgeGroupChange}
                disabled={isFirstImpressionSelected}
                options={AGES}
                renderOption={(option, isSelected) => (
                  <SelectOption label={option.label} isSelected={isSelected} disabled={isFirstImpressionSelected} />
                )}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

ReviewFilters.defaultProps = defaultProps;

export default ReviewFilters;
