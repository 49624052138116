import {useEffect, useState} from "react";

export default function useCalculateEllipsis(parentRef, childRefs, childList) {

    const [shouldShowEllipsis, setShouldShowEllipsis] = useState(false)

    useEffect(() => {
        calculateShowEllipsis(parentRef, childRefs, childList)
    }, [childList]);

    function calculateShowEllipsis(parentRef, childRefs, childList) {
        if (!childRefs.current || !parentRef.current) {
            return;
        }

        const widths = childList.reduce(
            (accumulator, currentValue) => accumulator + childRefs.current.get(currentValue).getBoundingClientRect().width + 4,
            0,
        );
        const parentWidth = parentRef.current.getBoundingClientRect().width
        setShouldShowEllipsis(parentWidth < widths)
    }

    return shouldShowEllipsis
}