import { forwardRef, useEffect, useRef, useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"
import {
    FAKE_FINANCIAL_ADVISER_PENSION_TRANSFERS_DROPDOWN_ID, FINANCIAL_ADVISER_PENSION_TRANSFERS_NON_SPECIALIST_ID,
    FINANCIAL_ADVISER_PENSION_TRANSFERS_SPECIALIST_ID
} from "../../__constants__";
import SelectArrows from "../vectors/SelectArrows";

const MultiSelect = forwardRef(
    ({
         onSelection,
         renderValue,
         defaultSelection,
         options,
         renderOption,
         className,
         optionValueKey = 'value',
         optionTextKey = 'label',
         id = 'default',
         disabled = false,
         showEllipsis = false,
         highlight= false,
         isDefaultOpen= false,
     }, ref) => {
    const [isDrowndownShown, setIsDropdownShown] = useState(isDefaultOpen)
    const [selectedOption, setSelectedOption] = useState(defaultSelection || [])
    const dropdownRef = useRef(null)

    useClickOutside(dropdownRef, () => setIsDropdownShown(false))

    useEffect(() => {
        if (defaultSelection && JSON.stringify(defaultSelection) !== JSON.stringify(selectedOption)) {
            setSelectedOption(defaultSelection)
        }
    }, [defaultSelection]);

    const handleSelectClick = () => {
        if (disabled) {
            return
        }

        setIsDropdownShown(!isDrowndownShown)
    }

    const handleOptionClick = (option) => {
        if (disabled) {
            return
        }

        setSelectedOption((prevState) => {
            let newOptionsList = []
            if (prevState.includes(option[optionValueKey])) {
                newOptionsList = prevState.filter(val => val !== option[optionValueKey])
            } else {
                newOptionsList = [...prevState, option[optionValueKey]]
            }
            onSelection(newOptionsList)
            return newOptionsList
        })
    }

    const handleOptionHover = (event) => {
        const { currentTarget } = event
        currentTarget.classList.toggle('bg-secondary-blue')
        currentTarget.classList.toggle('[&>*]:!text-white')
    }

    return (
        <div className="w-full" data-test={`select-box-container-${id}`} ref={dropdownRef}>
            <div className={`relative ${className}`}>
                <button
                    onClick={handleSelectClick}
                    type="button"
                    className={`${highlight ? 'ring-2 ring-secondary-blue' : ''} overflow-hidden ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:ring-secondary-blue hover:ring-2 focus:ring-2 focus:ring-secondary-blue'} box-border flex items-center h-11 relative w-full rounded-[2rem] border-0 bg-white py-1 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6`}
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                >
                    <div ref={ref} className="h-full w-full relative">
                        {renderValue()}
                    </div>
                    {showEllipsis && <span className={'pointer-events-none font-bold text-lg absolute inset-y-0 tracking-widest right-7 ml-2 flex items-center pr-2'}>...</span>}
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2">
                        <SelectArrows highlight={highlight} />
                    </span>
                </button>

                {isDrowndownShown &&
                    <ul className="absolute z-10 mt-1 pl-0 max-h-56 w-full overflow-auto rounded-2xl bg-white py-0 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        {options.map((option) => {
                            let isSelected = selectedOption.includes(option[optionValueKey])
                            if (option[optionValueKey] === FAKE_FINANCIAL_ADVISER_PENSION_TRANSFERS_DROPDOWN_ID) {
                                isSelected = selectedOption.includes(FINANCIAL_ADVISER_PENSION_TRANSFERS_SPECIALIST_ID) || selectedOption.includes(FINANCIAL_ADVISER_PENSION_TRANSFERS_NON_SPECIALIST_ID)
                            }

                            return (
                                <li key={`select-options:${option[optionValueKey]}-${option[optionTextKey]}`} onClick={() => handleOptionClick(option)} onMouseEnter={handleOptionHover} onMouseLeave={handleOptionHover} className={`relative ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} select-none py-2 pr-3 pl-3 text-gray-900 list-none`} id="listbox-option-0" role="option">
                                    {renderOption(option, isSelected, disabled)}
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
})

export default MultiSelect